<template>
  <div class="Div">
    <a-modal
      :visible="isShow"
      title="请填写接收人信息"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <div class="footerBtn">
          <a-button class="clearBtn" @click="handleCancel">关闭</a-button>
          <a-button class="OkBtn" @click="handleSubmit">确定</a-button>
        </div>
      </template>
     <a-form-model
      ref="ruleForm"
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      :rules="rules"
    >
      <a-form-model-item ref="refund_price" label="退款金额" prop="refund_price" >
        <a-input v-model="form.refund_price" placeholder="请输入退款金额"/>&nbsp;(注:退款只能线下退款)
      </a-form-model-item>
      <a-form-model-item ref="name" label="接收人" prop="name" >
        <a-input v-model="form.name" placeholder="请输入退款人姓名"/>
      </a-form-model-item>
       <a-form-model-item ref="mobile" label="电话" prop="name" >
        <a-input v-model="form.mobile" placeholder="请输入退款人电话"/>
      </a-form-model-item>
     </a-form-model>
    </a-modal>    
  </div>
</template>
<script >
import { refund } from "@/request/mis";
export default {
  props: ["fData",'isShow'],
   components: {
  },
  data() {
    return {
      labelCol: { span: 3},
      wrapperCol: { span: 14 },
       form: {
         name: '',
         mobile:'',
         refund_price:''
       },
       rules: {
         refund_price:[
            { required: true, message: '退款金额不能为空', trigger: 'change' },
         ]
       }
    
    };
  },
  watch:{
    form:{
      handler(){
        this.$set(this.form , 'refund_price' , this.getTofixed(this.form.refund_price))
      },
      deep:true,
    },
  },
  mounted() {
  },
  updated(){

  },
  methods: {
    handleCancel() {
      this.form.name="";
      this.form.mobile="";
      this.form.refund_price="";
      this.$emit('changeModal',false)
    },
    handleSubmit(){
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.refundRequest()
        } else {
          return false;
        }
      });
      
    },
    refundRequest(){
      const message={
        order_id:this.fData && this.fData.order_id,
        name:this.form.name,
        mobile:this.form.mobile,
        refund_price:this.form.refund_price
      }
       refund(message).then((res) => {
            if(res.data.status===200){
              this.$message.success('退款成功',1.5);
              this.$emit('changeModal',false);
              this.$emit('choose',true);
              this.form.name="";
              this.form.mobile="";
              this.form.refund_price="";
            }
          });
    } 
  }
};
</script>

<style lang="less" scoped>
/deep/.ant-modal-content {
  width: 80vh;
  background:rgba(237, 238, 242, 1);
}
/deep/.ant-modal-body {
  padding: 1.5vh;
}
/deep/.ant-form label {
  font-size: 1.8vh;
  color:rgba(80, 80, 80, 1);
}
/deep/.ant-input{
  width:15vw;
  height:3.9vh;
}
/deep/.ant-modal-title{ 
   font-size: 1.8vh;
}
/deep/.ant-btn {
 width: 14vh;
  height: 5vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
}
.zhu{
  font-size: 1.6vh;
  margin-left:1vw;
}
/deep/.ant-modal-header{
   background:rgba(237, 238, 242, 1);
}
/deep/.ant-col-14{
  width:auto;
}
.footerBtn{
  text-align: center;
  .clearBtn{
    margin-right: 6vw;
  }
}
</style>